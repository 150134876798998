import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "1em",
  height: "1em",
  viewBox: "0 0 48 48"
};
const _hoisted_2 = ["stroke"];
const _hoisted_3 = ["stroke"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("path", {
    fill: "none",
    stroke: _ctx.$vuetify.theme.current.colors['card-icon-color'],
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    d: "M8.4 6.45v35.1a2 2 0 0 0 1.95 2h2.38V4.5h-2.38A2 2 0 0 0 8.4 6.45m4.33-1.95v39h24.92a2 2 0 0 0 2-2V6.45a2 2 0 0 0-2-1.95Zm9.06 13.04v-4.29m-2.25 4.49v-4.49m4.36 4.29v-4.29m2.27 4.49v-4.49"
  }, null, 8, _hoisted_2), _createElementVNode("path", {
    fill: "none",
    stroke: _ctx.$vuetify.theme.current.colors['card-icon-color'],
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    d: "M26.17 17.74a3.32 3.32 0 0 1-6.63 0m3.31 17.01V21.11m9.93 13.64v-21.5c-5 1.87-4.75 11.25 0 13.6"
  }, null, 8, _hoisted_3)]);
}
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
                                                        
                                                              
                                                                  
  return _openBlock(), _createBlock(_component_v_list_item, {
    exact: "",
    nav: "",
    density: "compact",
    class: _normalizeClass($options.listItemClasses),
    justify: "space-between",
    to: _ctx.$attrs.to
  }, _createSlots({
    prepend: _withCtx(() => [!$props.hasCustomIconComponent ? (_openBlock(), _createBlock(_component_v_icon, {
      key: 0,
      icon: _ctx.$attrs.icon || _ctx.$attrs.prependIcon,
      color: "menu-button-icon-color-alternate"
    }, null, 8, ["icon"])) : (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.$attrs.icon || _ctx.$attrs.prependIcon), {
      key: 1,
      class: "mr-5"
    }))]),
    default: _withCtx(() => [$props.tooltip ? (_openBlock(), _createBlock(_component_v_tooltip, _mergeProps({
      key: 0,
      activator: "parent"
    }, $options.mergeTooltipWithDefaults($props.tooltip)), {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t($props.tooltip)), 1)]),
      _: 1
    }, 16)) : _createCommentVNode("", true)]),
    _: 2
  }, [!$props.rail ? {
    name: "title",
    fn: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$attrs.icon) + " ", 1), _createElementVNode("span", {
      class: _normalizeClass({
        'text-body-1': true,
        'text-menu-item-text-color': !_ctx.$props.append,
        'text-menu-item-text-color-alternate': _ctx.$props.append
      })
    }, _toDisplayString(_ctx.$t(_ctx.$attrs.title)), 3)]),
    key: "0"
  } : undefined]), 1032, ["class", "to"]);
}

/* Vuetify */
import { VIcon as _component_v_icon } from "vuetify/lib/components/VIcon/index.mjs"
import { VListItem as _component_v_list_item } from "vuetify/lib/components/VList/index.mjs"
import { VTooltip as _component_v_tooltip } from "vuetify/lib/components/VTooltip/index.mjs"


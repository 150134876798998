import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({
    page: $data.page,
    render: $options.render
  })), () => [_createElementVNode("div", {
    innerHTML: $data.page.html,
    class: "text-card-text-color markdown-content-loader",
    align: "center"
  }, null, 8, _hoisted_1)]);
}
import phrases from './phrases'
import pages from './pages'

export default {
  
  name : "Kussy",
  phrases,
  // words, 
  pages,
words : {
    additional : "Nützliches"
  },
  followUs : "Follow us on ...", 
  productTeaserDisclaimer : "Fictional Productteaser",
  buyMe : "Buy me!",
  comingSoon : "Coming Soon",
  // pages,
  
}
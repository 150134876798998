import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
                                                              
                                                        
                                                      
  const _component_v_speed_dial = _resolveComponent("v-speed-dial");
  const _component_v_fab = _resolveComponent("v-fab");
  return _openBlock(), _createBlock(_component_v_fab, {
    location: "bottom end",
    class: "social-button ma-4",
    icon: "",
    color: "action-button-color"
  }, {
    default: _withCtx(() => [_createVNode(_component_v_tooltip, {
      activator: "parent"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("phrases.followUs")), 1)]),
      _: 1
    }), _createVNode(_component_v_icon, null, {
      default: _withCtx(() => [_createTextVNode(" mdi-share ")]),
      _: 1
    }), _createVNode(_component_v_speed_dial, {
      activator: "parent"
    }, {
      default: _withCtx(() => [_createVNode(_component_v_btn, {
        icon: "",
        eager: "",
        color: "action-button-color",
        href: "https://twitter.com/Kussy_Lips",
        target: "_blank"
      }, {
        default: _withCtx(() => [_createVNode(_component_v_icon, null, {
          default: _withCtx(() => [_createTextVNode("mdi-twitter")]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_v_btn, {
        icon: "",
        color: "action-button-color",
        href: "https://twitter.com/Kussy_Lips",
        target: "_blank"
      }, {
        default: _withCtx(() => [_createVNode(_component_v_icon, null, {
          default: _withCtx(() => [_createTextVNode("mdi-facebook")]),
          _: 1
        })]),
        _: 1
      }), _createVNode(_component_v_btn, {
        icon: "",
        color: "action-button-color",
        href: "https://instagram.com/Kussy_Lips",
        target: "_blank"
      }, {
        default: _withCtx(() => [_createVNode(_component_v_icon, null, {
          default: _withCtx(() => [_createTextVNode("mdi-instagram")]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  });
}

/* Vuetify */
import { VBtn as _component_v_btn } from "vuetify/lib/components/VBtn/index.mjs"
import { VIcon as _component_v_icon } from "vuetify/lib/components/VIcon/index.mjs"
import { VTooltip as _component_v_tooltip } from "vuetify/lib/components/VTooltip/index.mjs"


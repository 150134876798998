export default {
  name: "TheLanguageToggle",
  props: {
    absolute: {
      type: Boolean,
      default: true
    },
    modelValue: {
      type: String,
      required: false
    }
  },
  computed: {
    languages() {
      return this.$i18n.availableLocales;
    },
    locale: {
      get() {
        return this.$i18n.locale;
      },
      set(val) {
        this.$i18n.locale = val;
      }
    }
  },
  watch: {
    locale(newValue) {
      this.$emit('update:modelValue', newValue);
    }
  },
  beforeMount() {
    if (this.$props.modelValue) {
      this.locale = this.$props.modelValue;
    }
  }
};
export default {
  name: "ErrorView",
  layout: "VerticallyCenteredLayout",
  props: {
    /** The code of the Error
     */
    error: {
      type: [String, Number],
      default: "404"
    }
  }
};
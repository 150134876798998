// import SidemenuEntry from "./SidemenuEntry.vue";
export default {
  name: "TheSidemenu",
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rail: false,
      state: true,
      lists: {
        default: [],
        prepend: [],
        append: []
      }
    };
  },
  watch: {
    state(value) {
      if (value) {
        this.rail = !value;
      }
    }
  },
  methods: {
    mergeTooltipOptionsWithDefaultTooltipOptions(opts) {
      const options = typeof opts == "object" ? opts : {
        text: opts
      };
      return {
        location: "start top",
        ...options
      };
    },
    mergeTooltipOptionsWithFooterTooltipOptions(opts) {
      const options = typeof opts == "object" ? opts : {
        text: opts
      };
      return {
        location: "top start",
        ...options
      };
    }
  },
  computed: {
    itemsToPrepend() {
      return this.$props.items.filter(({
        prepend
      }) => prepend);
    },
    itemsInDefault() {
      return this.$props.items.filter(({
        prepend,
        append,
        footer
      }) => !prepend && !append && !footer);
    },
    itemsToAppend() {
      return this.$props.items.filter(({
        append
      }) => append);
    },
    itemsInFooter() {
      return this.$props.items.filter(({
        footer
      }) => footer);
    }
  },
  components: {
    // SidemenuEntry,
  }
};
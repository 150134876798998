export default {
  name: "SidemenuEntry",
  inheritAttrs: false,
  props: {
    rail: Boolean,
    tooltip: [String, Object],
    value: Boolean,
    class: [String, Object, Array],
    hasCustomIconComponent: Boolean
  },
  emits: ["input"],
  data() {
    return {
      state: this.$props.value
    };
  },
  methods: {
    mergeTooltipWithDefaults(options) {
      const opts = typeof options == "string" ? {
        text: options
      } : options;
      return {
        location: "start top",
        ...opts
      };
    }
  },
  watch: {
    state(value) {
      this.$emit("update", value);
    }
  },
  computed: {
    miniVariant: {
      set(value) {
        this.state = value;
      },
      get() {
        return this.state && !this.$props.rail;
      }
    },
    listItemClasses() {
      let classes = {
        margin: !this.$props.rail,
        marginRail: this.$props.rail,
        "rounded-xl": false
      };
      const parseArrayOfClassNames = arr => arr.reduce((obj, item) => {
        obj[item] = true;
        return obj;
      }, {});
      if (typeof this.$props.class === "string") {
        return {
          ...classes,
          ...parseArrayOfClassNames(this.$props.class.split(" "))
        };
      }
      if (Array.isArray(this.$props.class)) {
        return {
          ...classes,
          ...parseArrayOfClassNames(this.$props.class)
        };
      }
      if (typeof this.$props.class == "object") {
        return {
          ...classes,
          ...this.$props.class
        };
      }
      return {
        ...classes
      };
    }
  }
};
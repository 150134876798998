import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/logos/name-with-second-line.png';
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_the_language_toggle = _resolveComponent("the-language-toggle");
  const _component_the_disclaimer_dialog = _resolveComponent("the-disclaimer-dialog");
  const _component_the_social_contact_menu = _resolveComponent("the-social-contact-menu");
                                                      
                                                                              
  const _component_router_link = _resolveComponent("router-link");
  const _component_the_sidemenu = _resolveComponent("the-sidemenu");
  const _component_router_view = _resolveComponent("router-view");
                                                      
  return _openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [_createVNode(_component_the_language_toggle), _createVNode(_component_the_disclaimer_dialog), _createVNode(_component_the_social_contact_menu), _createVNode(_component_the_sidemenu, {
      items: $options.stylizedItems,
      class: "sidemenu"
    }, {
      header: _withCtx(({}) => [_createVNode(_component_router_link, {
        to: '/'
      }, {
        default: _withCtx(() => [_createVNode(_component_v_fade_transition, {
          onTransitionend: _cache[0] || (_cache[0] = $event => _ctx.largeImageIsFading = false),
          onTransitionstart: _cache[1] || (_cache[1] = $event => _ctx.largeImageIsFading = true)
        }, {
          default: _withCtx(() => [_createVNode(_component_v_img, {
            class: "menu-icon",
            src: _imports_0
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }, 8, ["items"]), _createVNode(_component_router_view)]),
    _: 1
  });
}

/* Vuetify */
import { VApp as _component_v_app } from "vuetify/lib/components/VApp/index.mjs"
import { VImg as _component_v_img } from "vuetify/lib/components/VImg/index.mjs"
import { VFadeTransition as _component_v_fade_transition } from "vuetify/lib/components/transitions/index.mjs"


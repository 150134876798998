import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "1em",
  height: "1em",
  viewBox: "0 0 24 24"
};
const _hoisted_2 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("path", {
    fill: _ctx.$vuetify.theme.current.colors['card-icon-color'],
    d: "m12 23l-3-3H5q-.825 0-1.412-.587T3 18V4q0-.825.588-1.412T5 2h14q.825 0 1.413.588T21 4v14q0 .825-.587 1.413T19 20h-4zm0-7q2.15 0 3.575-1.5T17 11V6h-5Q9.975 6 8.488 7.425T7 11q0 .75.213 1.425t.587 1.25l-.4.4q-.3.3-.3.7t.3.7t.688.288t.712-.288l.375-.35q.6.425 1.313.65T12 16m1.25-6.375q.3.3.3.713t-.3.712l-1.875 1.875q-.3.3-.712.3t-.713-.3q-.275-.3-.275-.712t.275-.688l1.9-1.9q.3-.3.7-.3t.7.3"
  }, null, 8, _hoisted_2)]);
}
export default {
  name: "TheDisclaimerDialog",
  props: {
    timeoutUntilDialogIsDisplayed: {
      type: Number,
      default: 1500
    },
    timeoutAfterAgreed: {
      type: Number,
      default: 500
    }
  },
  data() {
    return {
      dialog: false,
      agreed: false,
      leaving: false
    };
  },
  computed: {
    page() {
      return this.$content("dialog");
    },
    storageKey() {
      return process.env.VUE_APP_DISCLAIMER_STORAGE_KEY;
    }
  },
  watch: {
    agreed(value) {
      if (value) {
        localStorage.setItem(this.storageKey, value);
      }
    }
  },
  methods: {
    toggleDialog() {
      setTimeout(() => this.dialog = !this.dialog, this.dialog && this.agreed ? this.timeoutAfterAgreed : this.timeoutUntilDialogIsDisplayed);
    }
  },
  beforeMount() {
    this.agreed = localStorage.getItem(process.env.VUE_APP_DISCLAIMER_STORAGE_KEY) == "true";
    if (!this.agreed) {
      this.toggleDialog();
    }
  }
};
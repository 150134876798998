import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "1em",
  height: "1em",
  viewBox: "0 0 24 24"
};
const _hoisted_2 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("path", {
    fill: _ctx.$vuetify.theme.current.colors['card-icon-color'],
    d: "M9.942 11.385q-1.246 0-2.123-.877t-.877-2.123t.877-2.123t2.123-.877t2.124.877q.876.877.876 2.123t-.876 2.123t-2.124.877m-5.961 7.23q-.44 0-.74-.299q-.299-.299-.299-.739v-.608q0-.767.377-1.232q.377-.466.954-.764q1.275-.65 2.51-1.004q1.234-.353 3.16-.353h.556q.265 0 .493.03q.404.031.535.41t-.161.671l-.772.771q-.448.448-.557 1.046t.234 1.089q.223.32.068.652q-.154.33-.539.33zm11.319-.323l4.696-4.696q.14-.14.345-.15q.203-.01.363.15t.16.354t-.16.354l-4.838 4.838q-.243.243-.566.243t-.565-.243l-1.839-1.838q-.14-.14-.15-.344t.15-.364t.354-.16t.354.16z"
  }, null, 8, _hoisted_2)]);
}
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
                                                                    
                                                                  
                                                            
                                                      
                                                                        
                                                        
  return _openBlock(), _createBlock(_component_v_card, {
    class: "error pa-2",
    elevation: "10"
  }, {
    default: _withCtx(() => [_createVNode(_component_v_card_title, {
      class: "mb-4"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("errors.title", {
        error: $props.error
      })), 1)]),
      _: 1
    }), _createVNode(_component_v_card_text, null, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t(`errors.${_ctx.code}`, {
        route: _ctx.$route.fullPath
      })), 1)]),
      _: 1
    }), _createVNode(_component_v_card_actions, null, {
      default: _withCtx(() => [_createVNode(_component_v_spacer), _createVNode(_component_v_btn, {
        to: {
          name: 'index'
        },
        "prepend-icon": "mdi-arrow-left",
        size: "x-large",
        class: "rounded-xl px-10",
        variant: "text"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("errors.goBack")), 1)]),
        _: 1
      }), _createVNode(_component_v_spacer)]),
      _: 1
    })]),
    _: 1
  });
}

/* Vuetify */
import { VBtn as _component_v_btn } from "vuetify/lib/components/VBtn/index.mjs"
import { VCardTitle as _component_v_card_title, VCardText as _component_v_card_text, VCardActions as _component_v_card_actions, VCard as _component_v_card } from "vuetify/lib/components/VCard/index.mjs"
import { VSpacer as _component_v_spacer } from "vuetify/lib/components/VGrid/index.mjs"


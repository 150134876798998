import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
                                                      
                                                                    
  return _openBlock(), _createBlock(_component_v_btn_toggle, {
    mandatory: "",
    modelValue: _ctx.$i18n.locale,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.$i18n.locale = $event),
    density: "compact",
    color: "language-toggle-color",
    class: _normalizeClass({
      'position-top-right': $props.absolute
    })
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.languages, (lang, i) => {
      return _openBlock(), _createBlock(_component_v_btn, {
        key: `Lang${i}`,
        value: lang,
        variant: "text",
        size: "small",
        density: "compact"
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(lang), 1)]),
        _: 2
      }, 1032, ["value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "class"]);
}

/* Vuetify */
import { VBtn as _component_v_btn } from "vuetify/lib/components/VBtn/index.mjs"
import { VBtnToggle as _component_v_btn_toggle } from "vuetify/lib/components/VBtnToggle/index.mjs"


import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_the_language_toggle = _resolveComponent("the-language-toggle");
                                                              
  const _component_MarkdownContent = _resolveComponent("MarkdownContent");
                                                                  
                                                      
                                                      
                                                      
                                                                        
                                                        
                                                                  
                                                            
  return _openBlock(), _createBlock(_component_v_dialog, {
    class: "dialog",
    modelValue: $data.dialog,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.dialog = $event),
    overlay: "",
    location: "center center",
    transition: "dialog-transition",
    "full-width": "",
    justifty: "center",
    persistent: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_v_container, {
      class: "fill-height"
    }, {
      default: _withCtx(() => [_createVNode(_component_v_row, {
        justify: "center"
      }, {
        default: _withCtx(() => [_createVNode(_component_v_col, {
          cols: "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_v_card, {
            "min-height": "50vh",
            width: "66vw",
            class: "flex-card"
          }, {
            default: _withCtx(() => [_createVNode(_component_v_toolbar, {
              density: "compact",
              class: "bg-card-background"
            }, {
              default: _withCtx(() => [_createVNode(_component_the_language_toggle)]),
              _: 1
            }), _createVNode(_component_v_card_text, null, {
              default: _withCtx(() => [_createVNode(_component_MarkdownContent, {
                "content-path": "dialog"
              })]),
              _: 1
            }), _createVNode(_component_v_card_actions, null, {
              default: _withCtx(() => [_createVNode(_component_v_row, {
                align: "end",
                justify: "space-around"
              }, {
                default: _withCtx(() => [_createVNode(_component_v_col, null, {
                  default: _withCtx(() => [_createVNode(_component_v_btn, {
                    width: "100%",
                    variant: "text",
                    color: $data.leaving ? 'warning' : '',
                    "prepend-icon": $data.leaving ? 'mdi-close' : '',
                    tile: "",
                    href: "http://www.google.com",
                    onClick: _cache[0] || (_cache[0] = $event => $data.leaving = true)
                  }, {
                    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("ui.buttons.leave")), 1)]),
                    _: 1
                  }, 8, ["color", "prepend-icon"])]),
                  _: 1
                }), _createVNode(_component_v_col, null, {
                  default: _withCtx(() => [_createVNode(_component_v_btn, {
                    variant: "text",
                    width: "100%",
                    tile: "",
                    onClick: _cache[1] || (_cache[1] = $event => {
                      $data.agreed = true;
                      $options.toggleDialog();
                    }),
                    color: $data.agreed ? 'success' : '',
                    "append-icon": $data.agreed ? 'mdi-check' : ''
                  }, {
                    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t("ui.buttons.confirm")), 1)]),
                    _: 1
                  }, 8, ["color", "append-icon"])]),
                  _: 1
                })]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]);
}

/* Vuetify */
import { VBtn as _component_v_btn } from "vuetify/lib/components/VBtn/index.mjs"
import { VCardText as _component_v_card_text, VCardActions as _component_v_card_actions, VCard as _component_v_card } from "vuetify/lib/components/VCard/index.mjs"
import { VDialog as _component_v_dialog } from "vuetify/lib/components/VDialog/index.mjs"
import { VCol as _component_v_col, VRow as _component_v_row, VContainer as _component_v_container } from "vuetify/lib/components/VGrid/index.mjs"
import { VToolbar as _component_v_toolbar } from "vuetify/lib/components/VToolbar/index.mjs"


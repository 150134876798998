// Contains commonly used phrases throughout the website

export default {
  followUs : "Follow us on ...",
  buyMe : "Buy me!",
  comingSoon : "Coming Soon",
  disclaimers : {
    teaserImage : "Fictional Productteaser"
  }

}
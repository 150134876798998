import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "1em",
  height: "1em",
  viewBox: "0 0 20 20"
};
const _hoisted_2 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("path", {
    fill: _ctx.$vuetify.theme.current.colors['menu-button-icon-color'],
    d: "M19.025 3.587c-4.356 2.556-4.044 7.806-7.096 10.175c-2.297 1.783-5.538.88-7.412.113c0 0-1.27 1.603-2.181 3.74c-.305.717-1.644-.073-1.409-.68C3.905 9.25 14.037 5.416 14.037 5.416s-7.149-.303-11.927 5.94c-.128-1.426-.34-5.284 3.36-7.65c5.016-3.211 14.572-.715 13.555-.119"
  }, null, 8, _hoisted_2)]);
}
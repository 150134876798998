import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
import _imports_0 from '@/assets/ui/branches/left.png';
const _withScopeId = n => (_pushScopeId("data-v-67893478"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "main-container fill-height",
  "fill-height": ""
};
const _hoisted_2 = {
  key: 0,
  class: "branch"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
                                                      
                                                      
  const _component_sidemenu_entry = _resolveComponent("sidemenu-entry");
                                                        
                                                        
                                                              
                                                      
                                                      
                                                                                  
  return _openBlock(), _createBlock(_component_v_navigation_drawer, {
    modelValue: $data.state,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.state = $event),
    rail: $data.rail,
    location: "start",
    permanent: !_ctx.$vuetify.display.mobile,
    temporary: _ctx.$vuetify.display.mobile,
    class: "bg-menuBackground pa-0 ma-0",
    app: ""
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_v_row, {
      class: "main noMarginOrPadding"
    }, {
      default: _withCtx(() => [_createVNode(_component_v_col, {
        cols: "12",
        class: "noMarginOrPadding"
      }, {
        default: _withCtx(() => [_createVNode(_component_v_row, {
          class: "header noMarginOrPadding"
        }, {
          default: _withCtx(() => [_createVNode(_component_v_col, {
            cols: "12",
            class: "noMarginOrPadding header"
          }, {
            default: _withCtx(() => [_renderSlot(_ctx.$slots, "header", _normalizeProps(_guardReactiveProps({
              rail: $data.rail
            })), () => [_ctx.$slots.header ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              class: "sidemenu__header ma-0 pa-0"
            }, {
              default: _withCtx(() => [_createVNode(_component_v_col)]),
              _: 1
            })) : _createCommentVNode("", true)], true)]),
            _: 3
          })]),
          _: 3
        }), $options.itemsToPrepend?.length > 0 ? (_openBlock(), _createBlock(_component_v_row, {
          key: 0,
          class: "prepend-list ma-0 pa-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_v_col, {
            class: "pa-0 ma-0"
          }, {
            default: _withCtx(() => [_renderSlot(_ctx.$slots, "prepend", _normalizeProps(_guardReactiveProps({
              items: $options.itemsToPrepend
            })), () => [_createVNode(_component_v_list, {
              opened: $data.lists.prepend,
              "collapse-icon": "mdi-chevron-up",
              "expand-icon": "mdi-chevron-down",
              class: "bg-menuBackground pa-0 ma-0"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.itemsToPrepend, item => {
                return _openBlock(), _createBlock(_component_sidemenu_entry, _mergeProps({
                  key: item.id
                }, item, {
                  rail: $data.rail
                }), null, 16, ["rail"]);
              }), 128))]),
              _: 1
            }, 8, ["opened"])], true)]),
            _: 3
          })]),
          _: 3
        })) : _createCommentVNode("", true), _createVNode(_component_v_row, {
          class: "main-list noMarginOrPadding my-5"
        }, {
          default: _withCtx(() => [_createVNode(_component_v_col, {
            class: "noPadding"
          }, {
            default: _withCtx(() => [_renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps({
              items: $options.itemsInDefault
            })), () => [_createVNode(_component_v_list, {
              opened: $data.lists.default,
              "collapse-icon": "mdi-chevron-up",
              "expand-icon": "mdi-chevron-down",
              class: "bg-menuBackground"
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.itemsInDefault, item => {
                return _openBlock(), _createBlock(_component_sidemenu_entry, _mergeProps({
                  key: item.id
                }, item, {
                  rail: $data.rail
                }), null, 16, ["rail"]);
              }), 128))]),
              _: 1
            }, 8, ["opened"])], true)]),
            _: 3
          })]),
          _: 3
        })]),
        _: 3
      })]),
      _: 3
    }), _createVNode(_component_v_row), _createVNode(_component_v_row, {
      class: "append ma-0 pa-0"
    }, {
      default: _withCtx(() => [_createVNode(_component_v_col, {
        cols: "12",
        class: "fill-height ma-0 pa-0"
      }, {
        default: _withCtx(() => [_createVNode(_component_v_row, {
          class: "appended-list ma-0 pa-0"
        }, {
          default: _withCtx(() => [_createVNode(_component_v_col, {
            class: "pa-0 ma-0"
          }, {
            default: _withCtx(() => [_renderSlot(_ctx.$slots, "append", _normalizeProps(_guardReactiveProps({
              items: $options.itemsToAppend
            })), () => [_createVNode(_component_v_list, {
              opened: $data.lists.append,
              rail: $data.rail
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.itemsToAppend, item => {
                return _openBlock(), _createBlock(_component_sidemenu_entry, _mergeProps({
                  key: item.id
                }, item, {
                  rail: $data.rail
                }), null, 16, ["rail"]);
              }), 128))]),
              _: 1
            }, 8, ["opened", "rail"])], true)]),
            _: 3
          })]),
          _: 3
        }), _createVNode(_component_v_row, {
          class: "footer"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.itemsInFooter, item => {
            return _openBlock(), _createBlock(_component_v_col, {
              class: "pa-0 ma-0",
              key: item.id
            }, {
              default: _withCtx(() => [_createVNode(_component_v_btn, {
                to: item.to,
                icon: "",
                tile: "",
                flat: "",
                size: item.size
              }, {
                default: _withCtx(() => [_createVNode(_component_v_icon, {
                  color: "blue",
                  size: item.size
                }, {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(item.icon), 1)]),
                  _: 2
                }, 1032, ["size"]), item?.tooltip ? (_openBlock(), _createBlock(_component_v_tooltip, _mergeProps({
                  key: 0
                }, $options.mergeTooltipOptionsWithFooterTooltipOptions(item.tooltip), {
                  activator: "parent"
                }), null, 16)) : _createCommentVNode("", true)]),
                _: 2
              }, 1032, ["to", "size"])]),
              _: 2
            }, 1024);
          }), 128))]),
          _: 1
        })]),
        _: 3
      }), _createVNode(_component_v_btn, {
        icon: "",
        variant: "plain",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.$vuetify.display.mobile ? $data.rail = !$data.rail : $data.state = !$data.state),
        class: _normalizeClass({
          'drag-handle': true,
          'drag-handle-when-rail-and-mobile': _ctx.$vuetify.display.mobile
        })
      }, {
        default: _withCtx(() => [_createVNode(_component_v_icon, {
          size: "x-large",
          class: "drag-icon",
          icon: "mdi-drag-vertical-variant"
        })]),
        _: 1
      }, 8, ["class"]), !_ctx.$vuetify.display.mobile ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_v_img, {
        src: _imports_0
      })])) : _createCommentVNode("", true)]),
      _: 3
    })])]),
    _: 3
  }, 8, ["modelValue", "rail", "permanent", "temporary"]);
}

/* Vuetify */
import { VBtn as _component_v_btn } from "vuetify/lib/components/VBtn/index.mjs"
import { VCol as _component_v_col, VRow as _component_v_row } from "vuetify/lib/components/VGrid/index.mjs"
import { VIcon as _component_v_icon } from "vuetify/lib/components/VIcon/index.mjs"
import { VImg as _component_v_img } from "vuetify/lib/components/VImg/index.mjs"
import { VList as _component_v_list } from "vuetify/lib/components/VList/index.mjs"
import { VNavigationDrawer as _component_v_navigation_drawer } from "vuetify/lib/components/VNavigationDrawer/index.mjs"
import { VTooltip as _component_v_tooltip } from "vuetify/lib/components/VTooltip/index.mjs"


import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "1em",
  height: "1em",
  viewBox: "0 0 50 50"
};
const _hoisted_2 = ["fill"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("svg", _hoisted_1, [_createElementVNode("path", {
    fill: _ctx.$vuetify.theme.current.colors['card-icon-color'],
    d: "M46.495 11H3.521c-1.382 0-2.513 1.257-2.513 2.5s1.131 2.499 2.513 2.499h.607v5h41.821v-5h.546c1.383 0 2.513-1.256 2.513-2.499s-1.13-2.5-2.513-2.5m-21.487 7.52c-.694 0-1.256-.579-1.256-1.292s.562-1.292 1.256-1.292c.695 0 1.256.579 1.256 1.292s-.561 1.292-1.256 1.292M4.128 30.998h41.821V23H4.128zm20.88-5.374c.695 0 1.256.578 1.256 1.291c0 .714-.561 1.292-1.256 1.292c-.694 0-1.256-.578-1.256-1.292c-.001-.713.562-1.291 1.256-1.291M4.128 37.895v5.814c0 .71.089 1.292.78 1.292h5.024c.691 0 1.004-.582 1.004-1.292v-3.71h28.205v3.71c0 .71.252 1.292.942 1.292h5.025c.689 0 .84-.582.84-1.292V33H4.128zm20.88-2.583c.695 0 1.256.579 1.256 1.291c0 .715-.561 1.291-1.256 1.291c-.694 0-1.256-.576-1.256-1.291c-.001-.711.562-1.291 1.256-1.291"
  }, null, 8, _hoisted_2)]);
}